import React from 'react';
import Web3 from 'web3';

import { sendBuyMessageToTelegram } from '../utils/telegramNotification';
import { sendBuyMessageToEmail } from '../utils/emailNotifications';

const chainId = '0x38';

const thanksMessage = {
  title: 'Спасибо за покупку! Проверяй свою почту и телеграм!',
  content: `Совсем скоро мы свяжемся с тобой и ты получишь доступ к курсу!
  Добро пожаловать в нашу команду!`,
};

const courseMapping = [
  { name: '🤓 PRO nodes', price: 99 },
  { name: '🚀 PRO nodes +3 ноды', price: 249 },
  { name: '🤑 PRO Max Nodes', price: 999 },
];

const BuyConfirmation = ({
  refer,
  closeWindow,
  showThanksMessage,
  thanksTitle,
  thanksContent,
  price,
}) => {
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [telegramId, setTelegramId] = React.useState('');
  const [course, setCourse] = React.useState('');
  const [txHash, setTxHash] = React.useState('');
  const [isMetamaks, setIsMetamask] = React.useState(false);
  const [account, setAccount] = React.useState();

  React.useEffect(() => {
    setIsMetamask(typeof window.ethereum != 'undefined');
    courseMapping.map((course) => (course.price === price ? setCourse(course.name) : ''));
  }, [price]);

  const onClickConnectButton = () => {
    window.ethereum.request({ method: 'eth_requestAccounts' }).then((accounts) => {
      setAccount(accounts[0]);

      if (window.ethereum.networkVersion !== chainId) {
        try {
          window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: chainId }],
          });
        } catch (err) {
          console.log(err);
        }
      }
    });
  };

  const onClickPayButton = () => {
    let actualPrice = price * 10 ** 18;
    let priceInHex = '0x' + actualPrice.toString(16);

    console.log(priceInHex);

    let transactionParam = {
      to: '0x55d398326f99059fF775485246999027B3197955',
      gas: '0x11170',
      from: account,
      data: getDataFieldValue('0xa5C4fA6DD27cB11b63633c98Bda3E6F079b26c67', priceInHex),
    };

    window.ethereum
      .request({
        method: 'eth_sendTransaction',
        params: [transactionParam],
      })
      .then((txhash) => {
        setIsMetamask(false);
        setTxHash(txhash);
      });
  };

  const getDataFieldValue = (tokenRecipientAddress, tokenAmount) => {
    const web3 = new Web3();
    const TRANSFER_FUNCTION_ABI = {
      constant: false,
      inputs: [
        { internalType: 'address', name: 'recipient', type: 'address' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'transfer',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    };
    return web3.eth.abi.encodeFunctionCall(TRANSFER_FUNCTION_ABI, [
      tokenRecipientAddress,
      tokenAmount,
    ]);
  };

  const onChangeName = (event) => {
    setName(event.target.value);
  };

  const onChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const onChangeTelegramId = (event) => {
    setTelegramId(event.target.value);
  };

  const onChangeCourse = (event) => {
    setCourse(event.target.value);
  };

  const onChangeTxHash = (event) => {
    setTxHash(event.target.value);
  };

  const onClickConfirm = () => {
    sendBuyMessageToEmail(name, email, telegramId, course, txHash);
    sendBuyMessageToTelegram(name, email, telegramId, course, txHash);
    closeWindow(false);
    thanksTitle(thanksMessage.title);
    thanksContent(thanksMessage.content);
    showThanksMessage(true);
    closeWindow(false);
  };

  return (
    <div className="login-popup bg-black p-5 md-p-l4 flex flex-wrap md-justify-between md-items-center show">
      <div ref={refer} className="box bg-indigo-lightest-10 scrool">
        <div className="img-area">
          <div className="img"></div>
          <h1 className="black text-center">
            Smart
            <br />
            Hamster
            <br />
            Nodes
            <br />
            Studio
          </h1>
        </div>
        <div className="form center scrool text-center">
          <div className="close white" onClick={() => closeWindow(false)}>
            <p>&times;</p>
          </div>
          <h1 className="white fs-m4 fw-400 text-center">Подтверждение покупки</h1>
          {isMetamaks ? (
            !account ? (
              <button
                id="connect-button"
                className="m-3 p-5 button w-50pc bg-black white hover-opacity-100 hover-scale-up-1 ease-300 metamask-button"
                onClick={() => onClickConnectButton()}
              >
                Подключить
              </button>
            ) : (
              <button
                id="pay-button"
                className="m-3 p-5 button w-50pc bg-black white hover-opacity-100 hover-scale-up-1 ease-300 metamask-button"
                onClick={() => onClickPayButton()}
              >
                Оплатить
              </button>
            )
          ) : (
            <>
              <div className="indigo-lightest fw-50 fs-s1 opacity-50 text-center break scrool">
                Указывайте email, мы отправим на него доступ к курсу.
                <br />
                Оплачивай курс в USDT на кошелек 0xa5C4fA6DD27cB11b63633c98Bda3E6F079b26c67 в сети
                Binance Smart Chain(BEP20) и отправляй хэш транзакции
              </div>
              <div id="payment-form" className="white py-5 scrool">
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Твое имя"
                    className="white form-control input-lg flex-grow-1 bw-0 fw-200 bg-indigo-lightest-10 ph-white"
                    onChange={(event) => onChangeName(event)}
                  />
                </div>
                <div className="form-group">
                  <input
                    id="form-email"
                    type="text"
                    placeholder="Email для связи"
                    className="white form-control input-lg flex-grow-1 bw-0 fw-200 bg-indigo-lightest-10 ph-white"
                    onChange={(event) => onChangeEmail(event)}
                  />
                </div>
                <div className="form-group">
                  <input
                    id="form-telegram"
                    type="text"
                    placeholder="Твой telegram ник"
                    className="white form-control input-lg flex-grow-1 bw-0 fw-200 bg-indigo-lightest-10 ph-white"
                    onChange={(event) => onChangeTelegramId(event)}
                  />
                </div>
                <div className="form-group">
                  <select
                    defaultValue={price}
                    className="white form-control select flex-grow-1 bw-0 fw-200 bg-indigo-lightest-10 select"
                    onChange={(event) => onChangeCourse(event)}
                  >
                    <option value={99}>PRO nodes 99$</option>
                    <option value={249}>PRO nodes + 3 ноды 249$</option>
                    <option value={999}>PRO Max Nodes 999$</option>
                  </select>
                </div>
                <div className="form-group">
                  <input
                    id="form-hash"
                    type="text"
                    value={txHash ? txHash : ''}
                    placeholder="Введи hash транзакции"
                    className="white form-control input-lg flex-grow-1 bw-0 fw-200 bg-indigo-lightest-10 ph-white"
                    onChange={(event) => onChangeTxHash(event)}
                  />
                </div>
                <button
                  className="button-lg bg-indigo indigo-lightest fw-300 fs-s3 sendPaymentResponse mt-5"
                  onClick={() => onClickConfirm()}
                >
                  Подтвердить покупку
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default BuyConfirmation;
