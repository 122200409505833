export function sendContactMeMessageToEmail(name, question) {
  let message =
    '🚨 🤓 👨‍💻 Привет, заинтересовался вашим курсом, свяжитесь со мной: 🍑 ' +
    name +
    ' 🍑 ' +
    ' ❓❓❓ У меня возник следующий вопрос: ' +
    question +
    ' ❓ ❓ ❓ ' +
    ' 💥 🔥 🚀';

  sendContactMe(message);
}

export function sendBuyMessageToEmail(name, email, telegramId, course, txHash) {
  let message =
    '🥳 🤑 Покупка курса 🥳 🤑 :' +
    ' Имя ' +
    name +
    ' Почта ' +
    email +
    ' Телеграмм ' +
    telegramId +
    ' Курс ' +
    course +
    ' Хэш транзакции ' +
    txHash +
    ' 🔥 🚀';

  sendPaymentInfo(message);
}

function sendContactMe(message) {
  const json = '{"message": "' + message + '"}';
  console.log(json);
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
      Accept: '*/*',
      Referer: 'https://smarthamster.tech/',
    },
    body: json,
  };

  const response = fetch('https://smarthamster.tech/php/contactMe.php', options)
    .then((response) => response.json())
    .then((response) => console.log(response))
    .catch((err) => console.error(err));

  return response;
}

function sendPaymentInfo(message) {
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: '{"message": "' + message + '"}',
  };

  const response = fetch('https://smarthamster.tech/php/buy.php', options)
    .then((response) => response.json())
    .then((response) => console.log(response))
    .catch((err) => console.error(err));

  return response;
}
